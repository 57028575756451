import Cookies from 'js-cookie';
import { useEffect, useRef } from 'react';
import { IDataTableColumn } from 'react-data-table-component';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Transit, TransitStatus } from '@e-origin/shared';

import TransitsStatusIndicator from '../../../../src/pages/transits/transit-status-indicator/transit-status-indicator.component';
import { STORAGE_KEYS } from '../../../../src/utils';
import { TableActionsIcons } from '../../../assets';
import customsSyncIcon from '../../../assets/icons/customs-sync-icon.svg';
import { CustomTableActionsComponent } from '../../../components';
import {
  customsSync,
  fetchTransits,
  generateNCTSResponse,
  generateTransitReport,
  sendOne,
} from '../../../stores/transitsSlice';

export const useTransitsColumns = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const actions = (row: Transit) => {
    return [
      {
        icon: TableActionsIcons.VIEW,
        text: 'View details',
        onClick: () => {
          history.push(`/transits-details/${row._id}`);
        },
      },
      {
        icon: TableActionsIcons.REPORT,
        text: 'Generate report',
        onClick: () => {
          dispatch(generateTransitReport(row._id));
        },
      },
      {
        if: [TransitStatus.NOT_SENT, TransitStatus.REJECTED, TransitStatus.UNLOAD_PERMISSION].includes(
          row.generalInfo?.status,
        ),
        icon: TableActionsIcons.SEND,
        text: 'Send',
        onClick: async () => {
          await sendOne(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
      },
      {
        if: [TransitStatus.SENT, TransitStatus.ACCEPTED, TransitStatus.RELEASED, TransitStatus.REMARKS_SENT].includes(
          row.generalInfo?.status,
        ),
        icon: customsSyncIcon,
        text: 'Customs sync',
        onClick: async () => {
          await customsSync(row._id);
          dispatch(fetchTransits({ persistPagination: true }));
        },
      },
    ];
  };

  const handleTableAction = async (actionType: string, row: Transit) => {
    const [, responseType] = actionType.match(/generateNCTSResponse-(.*)/);
    await generateNCTSResponse(row._id, responseType);
    dispatch(fetchTransits({ persistPagination: true }));
  };

  const buildColumns = (): IDataTableColumn<Transit>[] => [
    {
      name: 'Status',
      width: '75px',
      selector: 'generalInfo.status',
      sortable: true,
      cell: (row) => (
        <div className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          <TransitsStatusIndicator status={row.generalInfo.status} />
        </div>
      ),
    },
    {
      name: 'Transit ID',
      width: '100px',
      selector: 'counter',
      sortable: true,
    },
    {
      name: 'Name',
      selector: 'name',
      minWidth: '150px',
      sortable: true,
    },
    {
      name: 'Decl. type',
      width: '120px',
      selector: 'TransitOperation.declarationType',
    },
    {
      name: 'LRN',
      selector: 'TransitOperation.LRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'MRN',
      selector: 'TransitOperation.MRN',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Holder of transit proc.',
      cell: (row) => row.HolderOfTheTransitProcedure?.ContactPerson?.name,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.O. of Departure',
      cell: (row) => row.CustomsOfficeOfDeparture?.referenceNumber,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'C.O. of  Destination',
      cell: (row) => row.CustomsOfficeOfDestinationDeclared?.referenceNumber,
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Amount to be covered',
      selector: 'generalInfo.totalAmountGuaranteeToBeCovered',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Packages',
      selector: 'Consignment.totalNumberOfPackages',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Gross Mass',
      selector: 'Consignment.grossMass',
      sortable: true,
    },
    {
      name: 'Seals',
      selector: 'Consignment.totalNumberOfSeals',
      sortable: true,
    },

    // to be implemented
    // {
    //   name: 'C.R. of Departure',
    //   selector: '',
    //   minWidth: '200px',
    //   sortable: true,
    // },
    // {
    //   name: 'C.R. of Destination',
    //   selector: (row) => row.customsState?.controlResult,
    //   minWidth: '200px',
    //   sortable: true,
    // },

    {
      name: 'Deadline',
      selector: (row) => row.CustomsOfficeOfTransitDeclared?.[0]?.arrivalDateAndTimeEstimated || 'N/A',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Customer',
      selector: 'customer.name',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Template',
      selector: 'template.name',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Last update',
      selector: 'updatedAt',
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Actions',
      right: true,
      cell: (row) => (
        <CustomTableActionsComponent
          actions={actions(row)}
          secondaryActions={[]}
          onClick={(param?: string) => handleTableAction(param, row)}
          disableAfterClick={true}
          transitView={Cookies.get(STORAGE_KEYS.COOKIES.TRANSIT_VIEW) as any}
        />
      ),
    },
  ];

  const columns = useRef<IDataTableColumn<Transit>[]>([]);
  useEffect(() => {
    columns.current = buildColumns();
  }, []);

  return columns;
};
