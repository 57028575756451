import { get } from 'lodash';
import { Input } from '../../../components';

interface TransitInputHeaderProps {
  form: any;
  parents: any[];
  name: string;
  disabled: boolean;
  width?: number;
  placeholder?: string;
}

export const TransitInputHeader: React.FC<TransitInputHeaderProps> = ({
  form,
  parents,
  name,
  disabled,
  width = 50,
  placeholder,
}) => {
  const value = get(form.values, name);

  const parentValue = parents?.find((parent) => !!parent);

  return (
    <Input
      {...(!parentValue
        ? {
            name,
            onChange: form.handleChange,
            onBlur: form.handleBlur,
            value,
            disabled,
          }
        : {
            defaultBehaviour: true,
            disabled: true,
            value: parentValue,
          })}
      placeholder={placeholder}
      width={width}
      widthUnit="%"
      compact
    />
  );
};
