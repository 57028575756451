/* eslint-disable max-len */
/* eslint-disable max-statements */
/* eslint-disable no-param-reassign */

import { toast } from 'react-toastify';

/* eslint-disable complexity */
import { provide, TYPES } from '@e-origin/ioc';
import {
  CommodityCode,
  Declaration,
  DeclarationGoods,
  EvalMethodAiHsCode,
  EvalMethodDescription,
  EvalMethodStatisticalValue,
  EvalMethodWebValue,
  IRiskAnalysisUpdateFields,
  ISaveNotes,
  WebScraping,
} from '@e-origin/shared';

import { store } from '../stores';
import { request } from '../utils';

@provide(TYPES.RiskAnalysisService)
export class RiskAnalysisService {
  private store = store;

  public async retrieveDutyRateAndMadbValue(hsCode: string) {
    try {
      const commodityCode = this.extractDeclarationCommodityCode(hsCode, true);
      const dutyRate = await request({
        path: `external-api/taric-duty-rates?harmonizedSystem=${commodityCode.hsCodeCustomsSystem}&nomenclatureCode=${commodityCode.combinedNomenclatureCode}&taricCode=${commodityCode.taricCode}`,
        method: 'GET',
        authenticate: true,
      });
      const madbValue = await request({
        path: `external-api/madb-values?productNc=${commodityCode.hsCodeCustomsSystem}${commodityCode.combinedNomenclatureCode}&partnerIso=CN`,
        method: 'GET',
        authenticate: true,
      });
      return { dutyRate: dutyRate[0], madbValue };
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      toast.error(`Couldn't extract the duty rate`);
      return null;
    }
  }

  public async retrieveTaricDescriptions(hsCode: string) {
    try {
      const commodityCode = this.extractDeclarationCommodityCode(hsCode, true);
      const taricDescriptions = await request({
        path: `external-api/taric-description?harmonizedSystem=${commodityCode.hsCodeCustomsSystem}`,
        method: 'GET',
        authenticate: true,
      });
      return taricDescriptions;
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      toast.error(`Couldn't extract the duty rate`);
      return null;
    }
  }

  public async saveNotes(goodsItemsIds: string[], data: ISaveNotes): Promise<void> {
    try {
      await request({
        path: 'declarations/save-notes',
        method: 'POST',
        authenticate: true,
        dataObject: {
          goodsItemsIds,
          data,
        },
      });
      toast.success('Notes were successfully saved');
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
      toast.error(`Couldn't save the notes`);
    }
  }

  public evalMethodDescriptionCheckDisabled(goodDetails: DeclarationGoods): boolean {
    return (
      goodDetails.evalMethodDescription?.webMatchingDescription &&
      !goodDetails.evalMethodDescription?.suggestedDescriptionOfGoods &&
      !goodDetails.evalMethodDescription?.validatedDescriptionCheck
    );
  }

  public evalMethodAiHsCodeCheckDisabled(goodDetails: DeclarationGoods): boolean {
    return (
      goodDetails.evalMethodAiHsCode?.hsCodeOk &&
      !goodDetails.evalMethodAiHsCode?.suggestedHsCode &&
      !goodDetails.evalMethodAiHsCode?.validatedHSCodeCheck
    );
  }

  public evalMethodWebValueCheckDisabled(goodDetails: DeclarationGoods): boolean {
    return goodDetails.invoicedRateOk && !goodDetails.evalMethodWebValue?.validatedValueCheck;
  }

  public computeUpdatedDeclarationGoods(
    goodDetails: DeclarationGoods,
    newValues: IRiskAnalysisUpdateFields,
    declaration: Declaration,
  ): DeclarationGoods {
    const customer = this.store.getState().customers.list.find((c) => c._id === declaration.messageInfo.batch.customer);
    const customerMinimumRate = customer?.evaluationMethods?.evalMethodIMADBStatisticalValue?.minimumRate;
    let updatedGoodDetails: DeclarationGoods = {
      ...goodDetails,
      evalMethodDescription: this.computeEvalMethodDescription(goodDetails, newValues),
      evalMethodAiHsCode: this.computeEvalMethodAiHsCode(goodDetails, newValues),
      evalMethodWebValue: this.computeEvalMethodWebValue(goodDetails, newValues),
      evalMethodStatisticalValue: this.computeEvalMethodStatisticalValue(goodDetails, newValues, customerMinimumRate),
      webScraping: this.computeWebScraping(goodDetails, newValues),
    };

    updatedGoodDetails = {
      ...updatedGoodDetails,
      ...this.computeRootProperties(updatedGoodDetails),
    };

    return updatedGoodDetails;
  }

  private computeEvalMethodDescription(
    values: DeclarationGoods,
    newValues: IRiskAnalysisUpdateFields,
  ): EvalMethodDescription {
    if (newValues.validatedDescriptionCheckDisabled === undefined) {
      return values.evalMethodDescription;
    }

    return {
      ...values.evalMethodDescription,
      suggestedDescriptionOfGoods: !newValues?.validatedDescriptionCheck ? newValues?.newDescription : '',
      validatedDescriptionCheck: newValues?.validatedDescriptionCheck,
      webMatchingDescription:
        newValues?.validatedDescriptionCheck ||
        !!newValues?.newDescription ||
        newValues?.validatedDescriptionCheckDisabled,
    };
  }

  private computeEvalMethodAiHsCode(
    values: DeclarationGoods,
    newValues: IRiskAnalysisUpdateFields,
  ): EvalMethodAiHsCode {
    if (newValues.validatedHSCodeCheckDisabled === undefined) {
      return values.evalMethodAiHsCode;
    }

    return {
      ...values.evalMethodAiHsCode,
      suggestedHsCode: !newValues?.validatedHSCodeCheck ? newValues?.newHsCodeDescription : '',
      hsCodeOk:
        newValues?.validatedHSCodeCheck || !!newValues?.newHsCodeDescription || newValues?.validatedHSCodeCheckDisabled,
      validatedHSCodeCheck: newValues?.validatedHSCodeCheck,
      annotationNote: newValues?.annotationNote,
      annotationCustomsDecision: newValues?.annotationCustomsDecision,
      suggestedTaricRate: newValues?.newHsCodeDescriptionDutyRate,
    };
  }

  private computeEvalMethodWebValue(
    values: DeclarationGoods,
    newValues: IRiskAnalysisUpdateFields,
  ): EvalMethodWebValue {
    if (newValues.validatedValuesCheckDisabled === undefined) {
      return values.evalMethodWebValue;
    }

    const invoicedRateOk =
      newValues.invoicedRateOk &&
      values.evalMethodWebValue.validatedValueCheck &&
      !newValues?.validatedValueCheck &&
      !newValues?.validatedValuesCheckDisabled
        ? false
        : newValues?.validatedValueCheck ||
          (newValues.newPriceSaved >= 0 ? newValues.invoicedRateOk : values.evalMethodWebValue.invoicedRateOk);

    return {
      ...values.evalMethodWebValue,
      invoicedRate: newValues.newPriceSaved ? newValues.invoicedRate : values.evalMethodWebValue.invoicedRate,
      invoicedRateOk,
      validatedValueCheck: newValues?.validatedValueCheck,
    };
  }

  private computeEvalMethodStatisticalValue(
    values: DeclarationGoods,
    newValues: IRiskAnalysisUpdateFields,
    customerMinimumRate: number,
  ): EvalMethodStatisticalValue {
    if (newValues.validatedValuesCheckDisabled === undefined) {
      return values.evalMethodStatisticalValue;
    }

    const evalMethodStatisticalValue = {
      ...values.evalMethodStatisticalValue,
      validatedStatisticalValue: newValues?.validatedValueCheck,
    };
    const madbInvoicedRateOk = (rate) => {
      return newValues?.validatedValueCheck || rate > (customerMinimumRate || 1);
    };
    if (evalMethodStatisticalValue.lowest) {
      evalMethodStatisticalValue.lowest = {
        ...evalMethodStatisticalValue.lowest,
        madbInvoicedRateOk: madbInvoicedRateOk(evalMethodStatisticalValue.lowest.madbInvoicedRate),
      };

      if (
        ![null, undefined].includes(newValues.nowMadbValue) &&
        !!newValues.newHsCodeDescription &&
        newValues.newHsCodeDescription === values.evalMethodAiHsCode.suggestedHsCode
      ) {
        const madbInvoicedRate = values.evalMethodWebValue.invoicedAmountPerKg / newValues.nowMadbValue;
        evalMethodStatisticalValue.suggestedLowest = {
          ...evalMethodStatisticalValue.suggestedLowest,
          madbAmountPerKg: newValues.nowMadbValue,
          madbInvoicedRate,
          madbInvoicedRateOk: madbInvoicedRateOk(madbInvoicedRate),
        };
      } else {
        evalMethodStatisticalValue.suggestedLowest = null;
      }
    }
    if (evalMethodStatisticalValue.highest) {
      evalMethodStatisticalValue.highest = {
        ...evalMethodStatisticalValue.highest,
        madbInvoicedRateOk: madbInvoicedRateOk(evalMethodStatisticalValue.highest.madbInvoicedRate),
      };
    }
    return evalMethodStatisticalValue;
  }

  private computeWebScraping(values: DeclarationGoods, newValues: IRiskAnalysisUpdateFields): WebScraping {
    return {
      ...values.webScraping,
      webItemAmountResale: newValues.newPriceSaved || values.webScraping.webItemAmountResale || 0,
    };
  }

  private computeRootProperties(values: DeclarationGoods): Partial<DeclarationGoods> {
    return {
      invoicedRateOk: !!(
        values.evalMethodWebValue?.validatedValueCheck ||
        values.evalMethodWebValue?.invoicedRateOk ||
        (((!values.evalMethodStatisticalValue?.suggestedLowest &&
          values.evalMethodStatisticalValue?.lowest?.madbInvoicedRateOk) ||
          values.evalMethodStatisticalValue?.suggestedLowest?.madbInvoicedRateOk) &&
          values.webScraping.webItemAmountResale === 0)
      ),
    };
  }

  private extractDeclarationCommodityCode(hsCode: string, isHighValueDeclaration?: boolean): CommodityCode {
    const commodityCode: CommodityCode = {
      hsCode,
      hsCodeCustomsSystem: hsCode.substring(0, 6),
    };

    if (isHighValueDeclaration) {
      let remainingHsCode = hsCode.slice(6);
      if (remainingHsCode.length >= 2) {
        commodityCode.combinedNomenclatureCode = remainingHsCode.substring(0, 2);
      }
      remainingHsCode = remainingHsCode.slice(2);
      if (remainingHsCode.length >= 2) {
        commodityCode.taricCode = remainingHsCode.substring(0, 2);
      } else {
        commodityCode.taricCode = '00';
      }

      remainingHsCode = remainingHsCode.slice(2);
      if (remainingHsCode.length >= 2) {
        commodityCode.taricAdditionalCode = remainingHsCode.substring(0, 2);
      }
      remainingHsCode = remainingHsCode.slice(2);
      if (remainingHsCode.length >= 2) {
        commodityCode.nationalAdditionalCode = remainingHsCode.substring(0, 2);
      }
    }
    return commodityCode;
  }
}
