import styled, { css } from 'styled-components';
import tw from 'twin.macro';

interface IContainerProps {
  noSpaceBetweenButtons?: boolean;
  smallIcons?: boolean;
}

export const Container = styled.div<IContainerProps>`
  ${tw`
    flex
    items-center
    w-full
  `}

  ${({ noSpaceBetweenButtons }: IContainerProps) => {
    if (!noSpaceBetweenButtons) {
      return css`
        justify-content: flex-end;
        button {
          margin-left: 10px;

          &:first-of-type {
            margin-left: 0;
          }
        }
      `;
    }
    return css`
      justify-content: space-between;
    `;
  }}

  button {
    ${tw`
      flex
      justify-center
      items-center
      outline-none
      focus: outline-none
    `}

    svg {
      width: 100%;
      height: 100%;
    }

    &.svg-icon {
      width: ${({ smallIcons }: IContainerProps) => (smallIcons ? 16 : 22)}px;
      max-height: ${({ smallIcons }: IContainerProps) => (smallIcons ? 16 : 22)}px;

      svg {
        max-height: ${({ smallIcons }: IContainerProps) => (smallIcons ? 16 : 22)}px;
        path {
          fill: ${({ theme }: { theme: any }) => theme.colors.icons.primary} !important;
        }
      }

      &.with-text {
        width: auto;

        svg {
          width: ${({ smallIcons }: IContainerProps) => (smallIcons ? 16 : 22)}px;
          margin-right: 10px;
        }
      }
    }

    &[disabled] {
      opacity: 0.5;
    }
  }

  .transit-menu {
    overflow-x: hidden !important;
    z-index: 999;

    li {
      font-size: ${({ smallIcons }: IContainerProps) => (smallIcons ? '12px' : 'inherit')};
    }

    button {
      img {
        margin-right: 10px;
      }
    }

    &.move-it-up {
      margin-top: -20px;
    }

    &.column-view {
      margin-left: 12px;
      max-width: 230px;

      .szh-menu__item {
        padding: 0.375rem 0.5rem;
        font-size: 11px;

        img {
          height: 16px;
          width: 16px;
        }
      }
    }
  }
`;

export const IconContainer = styled.img`
  height: 21px;
  width: 21px;
`;

interface IDownloadImageButtonProps {
  isButtonDisabled?: boolean;
  webScrapingDone?: boolean;
}

export const DownloadImageButton = styled.button<IDownloadImageButtonProps>`
  opacity: ${({ isButtonDisabled }) => (isButtonDisabled ? 0.2 : 1)};
  cursor: ${({ webScrapingDone }) => (webScrapingDone ? 'pointer' : 'default !important')};
`;
