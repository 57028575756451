import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { selectTransit } from '../../../stores/transitsSlice';
import { FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { TransitInputHeader } from './transit-input-header.component';
import { TransitHouseConsignment } from '@e-origin/shared';

interface TransitConsigneeProps {
  form: FormikProps<any>;
  houseConsignment?: TransitHouseConsignment;
  editDisabled: boolean;
}
/* eslint-disable complexity */
export const TransitConsignee: React.FC<TransitConsigneeProps> = ({ form, houseConsignment, editDisabled }) => {
  const transit = useSelector(selectTransit);

  const transitConsignee = transit?.Consignment?.Consignee;

  const consigneeParent = transitConsignee || houseConsignment?.Consignee;

  return (
    <FormSection verticalPadding={0} noTopPadding>
      <FormSectionTitle noTopBorder>Consignee</FormSectionTitle>
      <FormRow>
        <TransitInputHeader
          form={form}
          name="Consignee.identificationNumber"
          placeholder="Identification Number"
          parents={consigneeParent ? [consigneeParent.identificationNumber] : []}
          disabled={editDisabled || !!consigneeParent}
        />
        <TransitInputHeader
          form={form}
          name="Consignee.name"
          placeholder="Name"
          parents={consigneeParent ? [consigneeParent.name] : []}
          disabled={editDisabled || !!consigneeParent}
        />
      </FormRow>
      <FormRow>
        <TransitInputHeader
          form={form}
          name="Consignee.Address.streetAndNumber"
          placeholder="Street and Number"
          parents={consigneeParent ? [transitConsignee.Address?.streetAndNumber] : []}
          disabled={editDisabled || !!consigneeParent}
        />

        <TransitInputHeader
          form={form}
          name="Consignee.Address.postcode"
          placeholder="Postcode"
          parents={consigneeParent ? [transitConsignee.Address?.postcode] : []}
          disabled={editDisabled || !!consigneeParent}
        />

        <TransitInputHeader
          form={form}
          name="Consignee.Address.city"
          placeholder="City"
          parents={consigneeParent ? [transitConsignee.Address?.city] : []}
          disabled={editDisabled || !!consigneeParent}
        />

        <TransitInputHeader
          form={form}
          name="Consignee.Address.country"
          placeholder="Country"
          parents={consigneeParent ? [transitConsignee.Address?.country] : []}
          disabled={editDisabled || !!consigneeParent}
        />
      </FormRow>
    </FormSection>
  );
};
