/* eslint-disable max-lines */
import { FormikProps } from 'formik';

import { Transit } from '@e-origin/shared';

import { retrieveCodes } from '../../../../src/stores/settingsSlice';
import { Input } from '../../../components';
import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../styles/common';
import { TransitDuplicateRow } from '../components';

interface TransitPersonsProps {
  form: FormikProps<Transit>;
  editDisabled: boolean;
}

export const TransitPersons: React.FC<TransitPersonsProps> = ({ form, editDisabled }) => {
  return (
    <FormContainer>
      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Holder of the transit procedure</FormSectionTitle>
        <FormRow>
          <Input
            name="HolderOfTheTransitProcedure.identificationNumber"
            placeholder="Identification number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.identificationNumber}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="HolderOfTheTransitProcedure.name"
            placeholder="Name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.name}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="HolderOfTheTransitProcedure.ContactPerson.name"
            placeholder="Name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.ContactPerson?.name}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="HolderOfTheTransitProcedure.ContactPerson.phoneNumber"
            placeholder="Phone Number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.ContactPerson?.phoneNumber}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="HolderOfTheTransitProcedure.ContactPerson.eMailAddress"
            placeholder="eMail Address"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.ContactPerson?.eMailAddress}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="HolderOfTheTransitProcedure.Address.streetAndNumber"
            placeholder="Street and Number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.Address?.streetAndNumber}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="HolderOfTheTransitProcedure.Address.postcode"
            placeholder="Postcode"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.Address?.postcode}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="HolderOfTheTransitProcedure.Address.city"
            placeholder="City"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.Address?.city}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="HolderOfTheTransitProcedure.Address.country"
            placeholder="Country"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.HolderOfTheTransitProcedure?.Address?.country}
            width={93}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Representative</FormSectionTitle>
        <FormRow>
          <Input
            name="Representative.identificationNumber"
            placeholder="Identification number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Representative?.identificationNumber}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Representative.status"
            placeholder="Status"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Representative?.status}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />

          {/* <Autocomplete
            placeholder="AEO"
            onChange={form.handleChange}
            options={[
              { value: 'true', label: 'True' },
              { value: 'false', label: 'False' },
            ]}
            value={{
              value: form.values.Authorisation,
              label: form.values.Authorisation,
            }}
          /> */}
        </FormRow>
        <FormRow>
          <Input
            name="Representative.ContactPerson.name"
            placeholder="Name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Representative?.ContactPerson?.name}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Representative.ContactPerson.phoneNumber"
            placeholder="Phone Number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Representative?.ContactPerson?.phoneNumber}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Representative.ContactPerson.eMailAddress"
            placeholder="eMail Address"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Representative?.ContactPerson?.eMailAddress}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Guarantees</FormSectionTitle>
        <TransitDuplicateRow
          addButtonLabel="Add Guarantee"
          form={form}
          inputs={[
            {
              type: 'Autocomplete',
              label: 'Guarantee Type',
              width: 24,
              name: 'Guarantee.guaranteeType',
              search: (searchText) => retrieveCodes('CL251', searchText),
            },

            {
              type: 'Input',
              name: 'Guarantee.GuaranteeReference.accessCode',
              label: 'Access Code',
              width: 24,
            },
            {
              type: 'Input',
              name: ' Guarantee.GuaranteeReference.currency',
              label: 'Currency',
              width: 24,
            },
            {
              type: 'Input',
              name: 'Guarantee.GuaranteeReference.GRN',
              label: 'GRN',
              width: 15,
            },

            {
              type: 'Input',
              name: 'Guarantee.GuaranteeReference.amountToBeCovered',
              label: 'AmountToBeCovered',
              width: 33,
            },
          ]}
          arrayPath="Authorisation"
          disabled={editDisabled}
        />
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Consignor</FormSectionTitle>
        <FormRow>
          <Input
            name="Consignment.Consignor.identificationNumber"
            placeholder="Identification number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.identificationNumber}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignor.name"
            placeholder="Name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.name}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignor.Address.streetAndNumber"
            placeholder="Street and Number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.Address?.streetAndNumber}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="Consignment.Consignor.Address.postcode"
            placeholder="Postcode"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.Address?.postcode}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignor.Address.city"
            placeholder="City"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.Address?.city}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignor.Address.country"
            placeholder="Country"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.Address?.country}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="Consignment.Consignor.Address.country"
            placeholder="Country"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.Address?.country}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignor.ContactPerson.name"
            placeholder="Name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.ContactPerson?.name}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignor.ContactPerson.phoneNumber"
            placeholder="Phone Number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.ContactPerson?.phoneNumber}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="Consignment.Consignor.ContactPerson.eMailAddress"
            placeholder="eMail Address"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignor?.ContactPerson?.eMailAddress}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Consignee</FormSectionTitle>
        <FormRow>
          <Input
            name="Consignment.Consignee.identificationNumber"
            placeholder="Identification number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignee?.identificationNumber}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignee.name"
            placeholder="Name"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignee?.name}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignee.Address.streetAndNumber"
            placeholder="Street and Number"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignee?.Address?.streetAndNumber}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
        <FormRow>
          <Input
            name="Consignment.Consignee.Address.postcode"
            placeholder="Postcode"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignee?.Address?.postcode}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignee.Address.city"
            placeholder="City"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignee?.Address?.city}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
          <Input
            name="Consignment.Consignee.Address.country"
            placeholder="Country"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.Consignment?.Consignee?.Address?.country}
            width={24}
            widthUnit="%"
            disabled={editDisabled}
          />
        </FormRow>
      </FormSection>

      <FormSection verticalPadding={20} topPadding={40} paddingBottom={0}>
        <FormSectionTitle noTopBorder>Additional supply chain actor</FormSectionTitle>
        <TransitDuplicateRow
          addButtonLabel="Add New Supply Chain Actor"
          form={form}
          inputs={[
            {
              type: 'Autocomplete',
              label: 'Role',
              name: 'role',
              width: 24,
              search: (searchText) => retrieveCodes('CL704', searchText),
            },
            {
              type: 'Input',
              width: 24,
              name: 'identificationNumber',
              label: 'Identification number',
            },
          ]}
          arrayPath="Consignment.AdditionalSupplyChainActor"
          disabled={editDisabled}
        />
      </FormSection>
    </FormContainer>
  );
};
