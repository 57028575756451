import { get } from 'lodash';
import { useSelector } from 'react-redux';

import { selectDeclarant } from '../../../stores/declarantSlice';
import { retrieveCodes } from '../../../stores/settingsSlice';
import { Autocomplete, Input } from '../../../components';

interface TransitAutocompleteHeaderProps {
  form: any;
  parents: any[];
  name: string;
  disabled: boolean;
  width?: number;
  placeholder?: string;
  code: string;
}

export const TransitAutocompleteHeader: React.FC<TransitAutocompleteHeaderProps> = ({
  form,
  parents,
  name,
  disabled,
  width = 50,
  placeholder,
  code,
}) => {
  const declarant = useSelector(selectDeclarant);

  const value = get(form.values, name);
  const parentValue = parents.find((parent) => !!parent);
  return (
    <>
      {!parentValue ? (
        <Autocomplete
          width={25}
          widthUnit="%"
          placeholder={placeholder}
          fetchOptions={(search: string) => retrieveCodes(code, search, declarant.language)}
          onChange={(selectedOption) => form.setFieldValue(name, selectedOption?.value)}
          value={{
            value,
            label: value,
          }}
          disabled={disabled}
          compact
        />
      ) : (
        <Input width={width} widthUnit="%" placeholder={placeholder} value={parentValue} disabled compact />
      )}
    </>
  );
};
