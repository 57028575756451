/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { FormContainer, FormRow, FormSection } from '../../../../../../../../styles/common';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';
import { useSelector } from 'react-redux';
import { TransitAutocompleteHeader, TransitInputHeader } from '../../../../../../components';
import { selectTransit } from '../../../../../../../../stores/transitsSlice';
import { Input } from '../../../../../../../../components';

interface HouseConsignmentDataProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export const HouseConsignmentData: React.FC<HouseConsignmentDataProps> = (props) => {
  const { form } = props;

  const transit = useSelector(selectTransit);

  return (
    <FormContainer>
      <FormSection verticalPadding={0}>
        <FormRow>
          <TransitAutocompleteHeader
            form={form}
            parents={[transit.TransitOperation.declarationType]}
            name="declarationType"
            disabled={props.disabled}
            width={25}
            placeholder="Declaration Type"
            code="CL231"
          />

          <TransitAutocompleteHeader
            form={form}
            parents={[transit.Consignment.countryOfDispatch]}
            name="countryOfDispatch"
            disabled={props.disabled}
            width={25}
            placeholder="Country Of Dispatch"
            code="CL008"
          />

          <Input
            name="grossMass"
            placeholder="Gross Mass"
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            value={form.values.grossMass}
            width={25}
            widthUnit="%"
            compact
            disabled={props.disabled}
          />

          <TransitInputHeader
            form={form}
            parents={[transit.Consignment.referenceNumberUCR]}
            name="referenceNumberUCR"
            disabled={props.disabled}
            width={25}
            placeholder="Reference Number UCR"
          />
        </FormRow>
      </FormSection>
    </FormContainer>
  );
};
