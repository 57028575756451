/* eslint-disable max-statements */
/* eslint-disable default-case */
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Batch,
  BatchStatusEnum,
  DeclarationCustomsFlowType,
  DeclarationStatus,
  EnvironmentCountries,
  getTotal,
  PostFlowDeclarationTypes,
  TemplateType,
} from '@e-origin/shared';

import { Button, CustomModal } from '../';
import Icons from '../../assets';
import { useConfig } from '../../hooks/use-config';
import {
  fetchBatch,
  sendAllDeclarationsToIDMS,
  sendAllDeclarationsToPLDA,
  sendBatchToBeGate,
  updateBatchStatus,
} from '../../stores/batchesSlice';
import { selectDeclarantSendingDelaySeconds } from '../../stores/declarantSlice';
import { fetchDeclarationGroups } from '../../stores/declarationGroupsSlice';
import { fetchDeclarationTypeForBatch, sendOneHighValue } from '../../stores/declarationsSlice';
import { selectSettings } from '../../stores/settingsSlice';
import ModalStyle from '../../styles/modal';
import * as Style from './send-declaration-modal.styles';

interface ISendDeclarationModalProps {
  batchId: string;
  title?: string;
  lrn?: string;
  onHide(): void;
}

enum SendBatchOptionsEnum {
  BE_GATE = 'BE_GATE',
  PLDA = 'PLDA',
  PRE_IDMS = 'PRE_IDMS',
  POST_IDMS = 'POST_IDMS',
  PRE_DECO = 'PRE_DECO',
  POST_DECO = 'POST_DECO',
}

const countDeclarationsForPreFlow = (batch: Batch, declarationType: string): number => {
  if (declarationType in PostFlowDeclarationTypes) {
    return 0;
  }

  return (
    getTotal(batch, DeclarationStatus.NOT_SENT, DeclarationCustomsFlowType.PRE) +
    getTotal(batch, DeclarationStatus.ANALYSIS, DeclarationCustomsFlowType.PRE) +
    getTotal(batch, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.PRE)
  );
};

const countDeclarationsForPostFlow = (batch: Batch, declarationType: string): number => {
  if (declarationType in PostFlowDeclarationTypes) {
    return (
      getTotal(batch, DeclarationStatus.NOT_SENT, DeclarationCustomsFlowType.POST) +
      getTotal(batch, DeclarationStatus.ANALYSIS, DeclarationCustomsFlowType.POST) +
      getTotal(batch, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.POST)
    );
  }

  return (
    getTotal(batch, DeclarationStatus.ACCEPTED, DeclarationCustomsFlowType.PRE) +
    getTotal(batch, DeclarationStatus.IN_CONTROL, DeclarationCustomsFlowType.PRE) +
    getTotal(batch, DeclarationStatus.REJECTED, DeclarationCustomsFlowType.POST)
  );
};

// eslint-disable-next-line complexity
export const SendBatchModal: React.FC<ISendDeclarationModalProps> = (props) => {
  const dispatch = useDispatch();
  const [declarationType, setDeclarationType] = useState<string>();
  const [showIDMSSendButtons, setShowIDMSSendButtons] = useState(false);
  const [batch, setBatch] = useState<Batch>(null);

  const { config } = useConfig();

  const sendingDelaySeconds = useSelector(selectDeclarantSendingDelaySeconds);

  const settings = useSelector(selectSettings);

  const fetchBatchData = async () => {
    const batchData = await fetchBatch(props.batchId);
    setBatch(batchData);

    if (batchData.isExport) {
      setShowIDMSSendButtons(true);
    }
  };

  const fetchDeclarationType = async () => {
    const data = await fetchDeclarationTypeForBatch(props.batchId);
    setDeclarationType(data.declarationType);
  };

  useEffect(() => {
    fetchBatchData();
    fetchDeclarationType();

    return () => {
      setBatch(null);
      setDeclarationType(null);
    };
  }, []);

  const showPre = useMemo(() => countDeclarationsForPreFlow(batch, declarationType) > 0, [declarationType, batch]);
  const showPost = useMemo(() => countDeclarationsForPostFlow(batch, declarationType) > 0, [declarationType, batch]);

  const handleBatchSending = (option: SendBatchOptionsEnum) => {
    // eslint-disable-next-line complexity
    setTimeout(async () => {
      switch (option) {
        case SendBatchOptionsEnum.BE_GATE: {
          dispatch(sendBatchToBeGate(batch._id));
          break;
        }
        case SendBatchOptionsEnum.PLDA: {
          dispatch(sendAllDeclarationsToPLDA(batch._id));
          break;
        }
        case SendBatchOptionsEnum.PRE_IDMS: {
          if (props.lrn) {
            await sendOneHighValue(props.lrn);
            dispatch(fetchDeclarationGroups());
            break;
          }

          dispatch(sendAllDeclarationsToIDMS({ batchId: batch._id, flow: DeclarationCustomsFlowType.PRE }));
          break;
        }
        case SendBatchOptionsEnum.POST_IDMS: {
          if (props.lrn) {
            await sendOneHighValue(props.lrn);
            dispatch(fetchDeclarationGroups());
            break;
          }

          dispatch(sendAllDeclarationsToIDMS({ batchId: batch._id, flow: DeclarationCustomsFlowType.POST }));
          break;
        }
        case SendBatchOptionsEnum.PRE_DECO: {
          dispatch(sendAllDeclarationsToIDMS({ batchId: batch._id, flow: DeclarationCustomsFlowType.PRE }));
          break;
        }
        case SendBatchOptionsEnum.POST_DECO: {
          dispatch(sendAllDeclarationsToIDMS({ batchId: batch._id, flow: DeclarationCustomsFlowType.POST }));
          break;
        }
      }

      dispatch(updateBatchStatus({ batchId: batch._id, status: BatchStatusEnum.SENDING }));
      props.onHide();
      // use a random interval to prevent double sending if another user clicks at the same time on send button
    }, Math.random() * 2000);
  };

  if (!batch || !declarationType) return <></>;

  return (
    <CustomModal show={true} onHide={props.onHide}>
      <ModalStyle.Header>
        <Style.TitleContainer>
          <ModalStyle.Title>{props.title}</ModalStyle.Title>
          <Style.BatchName>{batch.name}</Style.BatchName>
        </Style.TitleContainer>
        <ModalStyle.HeaderActions>
          <button onClick={props.onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>
      {batch.templateType === TemplateType.HIGH_VALUE_H1 &&
        !batch.isExport &&
        config?.COUNTRY === EnvironmentCountries.BE &&
        !showIDMSSendButtons && (
          <ModalStyle.Content>
            <Style.ButtonsContainer>
              <Button
                type="button"
                primary
                onClick={() => handleBatchSending(SendBatchOptionsEnum.BE_GATE)}
                disabled={batch.statistics.beGateSent > 0}
              >
                BE-GATE
              </Button>

              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.PLDA)}>
                PLDA
              </Button>
              {settings.highValuesIDMSEnabled && !showIDMSSendButtons && (
                <Button type="button" primary onClick={() => setShowIDMSSendButtons(true)}>
                  IDMS
                </Button>
              )}
            </Style.ButtonsContainer>
          </ModalStyle.Content>
        )}
      {showIDMSSendButtons && (
        <ModalStyle.Content>
          <Style.ButtonsContainer>
            <Button
              type="button"
              primary
              onClick={() => handleBatchSending(SendBatchOptionsEnum.PRE_IDMS)}
              disabled={!showPre}
            >
              Send PRE
            </Button>
            <Button
              type="button"
              primary
              onClick={() => handleBatchSending(SendBatchOptionsEnum.POST_IDMS)}
              disabled={!showPost}
            >
              Send POST
            </Button>
          </Style.ButtonsContainer>
        </ModalStyle.Content>
      )}

      {batch.templateType === TemplateType.HIGH_VALUE_H1 && config?.COUNTRY === EnvironmentCountries.NL && (
        <ModalStyle.Content>
          <Style.ButtonsContainer>
            <Button
              type="button"
              primary
              onClick={() => handleBatchSending(SendBatchOptionsEnum.PRE_DECO)}
              disabled={!showPre}
            >
              Send PRE
            </Button>
            <Button
              type="button"
              primary
              onClick={() => handleBatchSending(SendBatchOptionsEnum.POST_DECO)}
              disabled={!showPost}
            >
              Send POST
            </Button>
          </Style.ButtonsContainer>
        </ModalStyle.Content>
      )}

      {batch.templateType !== TemplateType.HIGH_VALUE_H1 && (
        <>
          <ModalStyle.Content style={{ padding: '30px 45px' }}>
            <Style.Description>
              Choose action: send PRE {countDeclarationsForPreFlow(batch, declarationType)} or send POST{' '}
              {countDeclarationsForPostFlow(batch, declarationType)}
            </Style.Description>
            <Style.Remark>
              NOTE: Declarations are sent {sendingDelaySeconds} seconds apart one from another.
            </Style.Remark>
          </ModalStyle.Content>
          <ModalStyle.Footer>
            <Button type="button" outline onClick={props.onHide}>
              Cancel
            </Button>
            {config?.COUNTRY === EnvironmentCountries.BE && (
              <Button type="button" primary onClick={() => handleBatchSending(SendBatchOptionsEnum.PRE_IDMS)}>
                Send
              </Button>
            )}
            {config?.COUNTRY === EnvironmentCountries.NL && (
              <Button
                type="button"
                primary
                onClick={() => handleBatchSending(SendBatchOptionsEnum.PRE_DECO)}
                disabled={!showPre}
              >
                Send PRE
              </Button>
            )}
            {config?.COUNTRY === EnvironmentCountries.NL && (
              <Button
                type="button"
                primary
                onClick={() => handleBatchSending(SendBatchOptionsEnum.POST_DECO)}
                disabled={!showPost}
              >
                Send POST
              </Button>
            )}
          </ModalStyle.Footer>
        </>
      )}
    </CustomModal>
  );
};
