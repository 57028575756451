/* eslint-disable no-control-regex */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
/* eslint-disable complexity */
/* eslint-disable max-statements */
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TransitTypeEnum } from '@e-origin/shared';

import Icons from '../../assets';
import { Button, CustomModal, Dropdown, Input } from '../../components';
import { fetchCustomers, selectCustomersNamesAndIds } from '../../stores/customersSlice';
import { createTransit, fetchTemplates, selectTemplates } from '../../stores/transitsSlice';
import { FormContainer, FormRow, FormSection } from '../../styles/common';
import ModalStyle from '../../styles/modal';

interface NewTransitModalProps {
  isUpdate?: boolean;
  onHide(): void;
  transitType?: TransitTypeEnum;
  afterCreate(): void;
}

const NewTransitModal: FC<NewTransitModalProps> = ({ isUpdate, onHide, afterCreate }) => {
  const dispatch = useDispatch();

  const [submitting, setSubmitting] = useState(false);
  const templatesList = useSelector(selectTemplates);
  const customersNamesAndIds = useSelector(selectCustomersNamesAndIds);

  const isCustomersNamesAndIdsEmpty = customersNamesAndIds.length === 0;

  useEffect(() => {
    if (!templatesList.length) {
      dispatch(fetchTemplates());
    }

    if (!customersNamesAndIds.length) {
      dispatch(fetchCustomers());
    }
  }, []);

  // todo - will be used later
  // const filterTemplates = (list) =>
  //   list.filter(() => transitType === TransitTypeEnum.DEPARTURE || transitType === TransitTypeEnum.ARRIVAL);

  // const filteredTemplates = filterTemplates(templatesList);
  const formik = useFormik({
    initialValues: {
      name: '',
      customer: '',
      template: '',
    },

    onSubmit: async (values) => {
      await createTransit(values);
      afterCreate();

      onHide();
    },
    enableReinitialize: false,
  });

  const startFormSubmit = () => {
    if (!submitting) {
      formik.submitForm();
    }
  };

  const isProcessButtonDisabled = !formik.values.template || isCustomersNamesAndIdsEmpty;

  return (
    <CustomModal
      show={true}
      onHide={() => {
        setSubmitting(false);
        onHide();
      }}
    >
      <ModalStyle.Header>
        <ModalStyle.Title>New Transit</ModalStyle.Title>
        <ModalStyle.HeaderActions>
          <button onClick={onHide}>
            <img src={Icons.CloseIcon} alt="" />
          </button>
        </ModalStyle.HeaderActions>
      </ModalStyle.Header>

      <ModalStyle.Content>
        <FormContainer onSubmit={formik.handleSubmit}>
          <FormSection verticalPadding={20} topPadding={40} paddingBottom={60}>
            <FormRow>
              <Input
                name="name"
                placeholder="Transit name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isUpdate}
                width={100}
                widthUnit="%"
              />
            </FormRow>

            <FormRow>
              <Dropdown
                placeholder="Select customer"
                options={customersNamesAndIds}
                disabled={isUpdate}
                onChange={(selectedOption) => {
                  formik.setFieldValue('customer', selectedOption?.value);
                  formik.touched.customer = true;
                }}
                invalid={
                  formik.touched.customer && formik.errors.customer !== undefined && formik.errors.customer.length > 0
                }
                maxMenuHeight={200}
              />
            </FormRow>

            <FormRow>
              <Dropdown
                placeholder="Select template"
                options={templatesList}
                disabled={isUpdate}
                onChange={(selectedOption) => {
                  formik.setFieldValue('template', selectedOption?.value);
                  formik.touched.template = true;
                }}
                invalid={
                  formik.touched.template && formik.errors.template !== undefined && formik.errors.template.length > 0
                }
                menuOverflow={true}
                maxMenuHeight={200}
              />
            </FormRow>
          </FormSection>
        </FormContainer>
        <ModalStyle.Footer>
          <Button type="button" key="cancel-transit-modal-btn" onClick={onHide} disabled={submitting}>
            Cancel
          </Button>
          <Button
            type="button"
            primary
            key="create-transit-modal-btn"
            onClick={startFormSubmit}
            showLoading={submitting}
            disabled={submitting || isProcessButtonDisabled}
          >
            Create
          </Button>
        </ModalStyle.Footer>
      </ModalStyle.Content>
    </CustomModal>
  );
};

export default NewTransitModal;
