/* eslint-disable complexity */

import { FormRow } from '../../../../styles/common';
import { Autocomplete, Button, Input } from '../../../../components';
import trashIcon from '../../../../assets/icons/trash-icon.svg';
import * as Style from './transit-duplicate-row.style';
import cloneDeep from 'lodash/cloneDeep';
import { FormikProps } from 'formik';

interface InputConfig {
  type: 'Autocomplete' | 'Input';
  name: string;
  search?: (searchText: string) => Promise<{ value: string; label: string }[]>;
  label: string;
  width?: number;
}

interface TransitDuplicateRowProps {
  form: FormikProps<any>;
  inputs: InputConfig[];
  compact?: boolean;
  arrayPath: string;
  addButtonLabel: string;
  inputsPerRow?: number;
  disabled?: boolean;
}

export const TransitDuplicateRow: React.FC<TransitDuplicateRowProps> = ({
  form,
  inputs,
  arrayPath,
  addButtonLabel,
  compact,
  inputsPerRow = 3,
  disabled = false,
}) => {
  const rows = cloneDeep(form.getFieldProps(arrayPath).value || []);

  const addRow = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    form.setFieldValue(arrayPath, [...rows, {}]);
  };

  const removeRow = (rowIdx: number) => {
    const updatedRows = rows.filter((_, idx) => idx !== rowIdx);
    form.setFieldValue(arrayPath, updatedRows);
  };

  const chunkInputs = (inputList: InputConfig[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < inputList.length; i += chunkSize) {
      chunks.push(inputList.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const renderInputField = (inputConfig: InputConfig, rowIdx: number, inputConfigIdx: number) => {
    const inputName = `${arrayPath}[${rowIdx}].${inputConfig.name}`;
    return inputConfig.type === 'Autocomplete' ? (
      <Autocomplete
        key={inputConfigIdx}
        placeholder={`${inputConfig.label} ${rowIdx + 1}`}
        fetchOptions={inputConfig.search}
        onChange={(selectedOption) => form.setFieldValue(inputName, selectedOption.value)}
        value={{
          value: form.values[inputName] || '',
          label: form.values[inputName] || '',
        }}
        width={inputConfig.width || 100}
        widthUnit="%"
        compact={!!compact}
        disabled={disabled}
      />
    ) : (
      <Input
        key={inputConfigIdx}
        placeholder={`${inputConfig.label} ${rowIdx + 1}`}
        onChange={(evt) => form.setFieldValue(inputName, evt.target.value)}
        name={inputName}
        width={inputConfig.width || 100}
        widthUnit="%"
        defaultBehaviour
        compact={!!compact}
        disabled={disabled}
      />
    );
  };

  const renderInputs = (inputList: InputConfig[], rowIdx: number) => {
    const inputChunks = chunkInputs(inputList, inputsPerRow);

    return (
      <>
        {inputChunks.map((chunk, chunkIdx) => (
          <FormRow key={`chunk-${chunkIdx}`}>
            {chunk.map((inputConfig, inputConfigIdx) => renderInputField(inputConfig, rowIdx, inputConfigIdx))}
            {chunkIdx === 0 && !disabled && (
              <Style.DeleteButton type="button" onClick={() => removeRow(rowIdx)}>
                <img src={trashIcon} alt="Remove" />
              </Style.DeleteButton>
            )}
          </FormRow>
        ))}
      </>
    );
  };

  return (
    <>
      {rows.map((_, rowIdx) => (
        <div key={`row-${rowIdx}`}>
          {renderInputs(inputs, rowIdx)}
          {rowIdx < rows.length - 1 && <Style.Separator widthPercentage={inputs.length === 2 ? 49 : 74} />}
        </div>
      ))}
      {!disabled && (
        <Style.Item>
          <Button type="button" outline onClick={addRow}>
            {addButtonLabel}
          </Button>
        </Style.Item>
      )}
    </>
  );
};
