/* eslint-disable complexity */
import { RecursivePartial, Transit, TransitHouseConsignment } from '@e-origin/shared';

export interface HouseItemFormInitializerReturn extends RecursivePartial<TransitHouseConsignment> {
  documents: {
    PreviousDocument: any[];
    SupportingDocument: any[];
    TransportDocument: any[];
    AdditionalReference: any[];
    AdditionalInformation: any[];
  };
}

export const houseFormIntializer = (
  item: TransitHouseConsignment,
  transit: Transit,
): HouseItemFormInitializerReturn => ({
  countryOfDispatch: !transit.Consignment.countryOfDispatch ? item?.countryOfDispatch || '' : null,
  grossMass: item?.grossMass || '',
  referenceNumberUCR: !transit.Consignment.referenceNumberUCR ? item?.referenceNumberUCR || '' : null,
  Consignor: !transit.Consignment?.Consignor
    ? {
        identificationNumber: item?.Consignor?.identificationNumber || '',
        name: item?.Consignor?.name || '',
        Address: {
          streetAndNumber: item?.Consignor?.Address?.streetAndNumber || '',
          postcode: item?.Consignor?.Address?.postcode || '',
          city: item?.Consignor?.Address?.city || '',
          country: item?.Consignor?.Address?.country || '',
        },
      }
    : null,
  Consignee: !transit.Consignment?.Consignee
    ? {
        identificationNumber: item?.Consignee?.identificationNumber || '',
        name: item?.Consignee?.name || '',
        Address: {
          streetAndNumber: item?.Consignee?.Address?.streetAndNumber || '',
          postcode: item?.Consignee?.Address?.postcode || '',
          city: item?.Consignee?.Address?.city || '',
          country: item?.Consignee?.Address?.country || '',
        },
      }
    : null,
  AdditionalSupplyChainActor: item?.AdditionalSupplyChainActor.length
    ? item.AdditionalSupplyChainActor
    : [{ role: '', identificationNumber: '' }],
  DepartureTransportMeans: item?.DepartureTransportMeans.length
    ? item.DepartureTransportMeans
    : [{ typeOfIdentification: '', identificationNumber: '', nationality: '' }],
  documents: {
    PreviousDocument: item?.PreviousDocument || [],
    SupportingDocument: item?.SupportingDocument || [],
    TransportDocument: item?.TransportDocument || [],
    AdditionalReference: item?.AdditionalReference || [],
    AdditionalInformation: item?.AdditionalInformation || [],
  },
});
