/* eslint-disable complexity */
import { ConsignmentItem, RecursivePartial, Transit, TransitHouseConsignment } from '@e-origin/shared';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GoodsItemFormInitializerReturn extends RecursivePartial<ConsignmentItem> {
  documents: {
    PreviousDocument: any[];
    SupportingDocument: any[];
    TransportDocument: any[];
    AdditionalReference: any[];
    AdditionalInformation: any[];
  };
}

export const goodsItemFormIntializer = (
  item: ConsignmentItem,
  house: TransitHouseConsignment,
  transit: Transit,
): GoodsItemFormInitializerReturn => ({
  declarationType: !transit.TransitOperation?.declarationType ? item?.declarationType || '' : null,
  countryOfDispatch: !house?.countryOfDispatch ? item?.countryOfDispatch || '' : null,
  countryOfDestination: !transit.Consignment.countryOfDestination ? item?.countryOfDestination || '' : null,
  referenceNumberUCR:
    !transit.Consignment.referenceNumberUCR && !house?.referenceNumberUCR ? item?.referenceNumberUCR || '' : null,
  Commodity: {
    descriptionOfGoods: item?.Commodity?.descriptionOfGoods || '',
    DangerousGoods: item?.Commodity?.DangerousGoods || [{ UNNumber: '' }],
    CommodityCode: {
      hsCode: item?.Commodity?.CommodityCode?.hsCode || '',
    },
    GoodsMeasure: {
      grossMass: item?.Commodity?.GoodsMeasure?.grossMass || '',
      netMass: item?.Commodity.GoodsMeasure?.netMass || '',
      supplementaryUnits: item?.Commodity?.GoodsMeasure?.supplementaryUnits || '',
    },
  },

  Packaging: item?.Packaging || [{ typeOfPackages: '', numberOfPackages: '', shippingMarks: '' }],
  Consignee:
    !house?.Consignee && !transit.Consignment?.Consignee
      ? {
          identificationNumber: item?.Consignee?.identificationNumber || '',
          name: item?.Consignee?.name || '',
          Address: {
            streetAndNumber: item?.Consignee?.Address?.streetAndNumber || '',
            postcode: item?.Consignee?.Address?.postcode || '',
            city: item?.Consignee?.Address?.city || '',
            country: item?.Consignee?.Address?.country || '',
          },
        }
      : null,
  AdditionalSupplyChainActor: !item?.AdditionalSupplyChainActor?.length
    ? item?.AdditionalSupplyChainActor
    : [{ role: '', identificationNumber: '' }],
  TransportCharges: {
    methodOfPayment: item?.TransportCharges?.methodOfPayment,
  },
  documents: {
    PreviousDocument: item?.PreviousDocument?.length ? item.PreviousDocument : [],
    SupportingDocument: item?.SupportingDocument?.length ? item.SupportingDocument : [],
    TransportDocument: item?.TransportDocument?.length ? item.TransportDocument : [],
    AdditionalReference: item?.AdditionalReference.length ? item.AdditionalReference : [],
    AdditionalInformation: item?.AdditionalInformation.length ? item.AdditionalInformation : [],
  },
});
