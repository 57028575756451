/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { FormContainer, FormRow, FormSection, FormSectionTitle } from '../../../../../../../../styles/common';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';
import { TransitConsignee, TransitDuplicateRow } from '../../../../../../components';
import { TransitHouseConsignment } from '@e-origin/shared';
import { retrieveCodes } from '../../../../../../../../stores/settingsSlice';
import { Input } from '../../../../../../../../components';
import { useSelector } from 'react-redux';
import { selectTransit } from '../../../../../../../../stores/transitsSlice';

interface GoodsItemStakeholdersProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  houseConsignment: TransitHouseConsignment;
  disabled: boolean;
}

export const GoodsItemStakeholders: React.FC<GoodsItemStakeholdersProps> = (props) => {
  const { form } = props;

  const transit = useSelector(selectTransit);

  return (
    <FormContainer>
      <TransitConsignee form={form} houseConsignment={props.houseConsignment} editDisabled={props.disabled} />
      <FormSection verticalPadding={0}>
        <FormSectionTitle>Additional supply chain actor</FormSectionTitle>
        {(transit.Consignment?.AdditionalSupplyChainActor || []).map((doc, docIdx) => (
          <FormRow key={docIdx}>
            <Input width={50} widthUnit="%" placeholder="Role" value={doc.role} compact disabled />
            <Input
              width={50}
              widthUnit="%"
              placeholder="Identification number"
              value={doc.identificationNumber}
              compact
              disabled
            />
          </FormRow>
        ))}
        {(props.houseConsignment?.AdditionalSupplyChainActor || []).map((doc, docIdx) => (
          <FormRow key={docIdx}>
            <Input width={50} widthUnit="%" placeholder="Role" value={doc.role} compact disabled />
            <Input
              width={50}
              widthUnit="%"
              placeholder="Identification number"
              value={doc.identificationNumber}
              compact
              disabled
            />
          </FormRow>
        ))}
        <TransitDuplicateRow
          addButtonLabel="Add new additional supply chain actor"
          form={form}
          inputs={[
            {
              type: 'Autocomplete',
              label: 'Role',
              name: 'role',
              search: (searchText) => retrieveCodes('CL704', searchText),
            },
            {
              type: 'Input',
              label: 'Identification number',
              name: 'identificationNumber',
            },
          ]}
          arrayPath="AdditionalSupplyChainActor"
          compact
          disabled={props.disabled}
        />
      </FormSection>
    </FormContainer>
  );
};
