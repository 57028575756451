/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-statements */
/* eslint-disable complexity */
import { TransitDocumentsEnum, TransitHouseConsignment } from '@e-origin/shared';
import { FormikProps } from 'formik';

import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';
import { TransitDocumentsComponent } from '../../../../../../transit-documents-component';
import { useMemo } from 'react';

interface GoodsItemDocumentsProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  houseConsignment: TransitHouseConsignment;
  disabled: boolean;
}

export interface DocumentItemProps {
  type: TransitDocumentsEnum;
  label?: string;
  code: string;
  referenceNumber?: string;
  dateOfValidity?: string;
  dataSource: any;
  dataSourceIndex: number;
}

export const GoodsItemDocuments: React.FC<GoodsItemDocumentsProps> = ({ form, houseConsignment, disabled }) => {
  const handleDocumentDelete = (documentType: any, documentIndex: number) => {
    form.setFieldValue(`documents.${documentType}`, [
      ...(form.values.documents[documentType] || []).filter((_, idx) => idx !== documentIndex),
    ]);
  };

  const handleSavingDocument = (savedDocument) => {
    if (savedDocument.docIndex >= 0) {
      const updatedDocuments = form.values.documents[savedDocument.type].map((doc, idx) => {
        if (idx === savedDocument.docIndex) {
          return savedDocument.data;
        }
        return doc;
      });
      form.setFieldValue(`documents.${savedDocument.type}`, updatedDocuments);
    } else {
      form.setFieldValue(`documents.${savedDocument.type}`, [
        ...(form.values.documents[savedDocument.type] || []),
        savedDocument.data,
      ]);
    }
  };

  const previewDocuments = useMemo(
    () => ({
      PreviousDocument: houseConsignment?.PreviousDocument?.length ? houseConsignment.PreviousDocument : [],
      SupportingDocument: houseConsignment?.SupportingDocument?.length ? houseConsignment.SupportingDocument : [],
      TransportDocument: houseConsignment?.TransportDocument?.length ? houseConsignment.TransportDocument : [],
      AdditionalReference: houseConsignment?.AdditionalReference.length ? houseConsignment.AdditionalReference : [],
      AdditionalInformation: houseConsignment?.AdditionalInformation.length
        ? houseConsignment.AdditionalInformation
        : [],
    }),
    [houseConsignment],
  );

  return (
    <TransitDocumentsComponent
      previewDocuments={previewDocuments}
      documents={form.values.documents}
      disabled={disabled}
      onDelete={handleDocumentDelete}
      onSave={handleSavingDocument}
    />
  );
};
