import { FC, useEffect, useState } from 'react';

import { Button, Modal } from '../';
import * as Style from './notification-modal.style';

interface INotificationModalProps {
  confirmButtonText?: string;
  cancelButtonText?: string;
  isDelete?: boolean;
  onConfirm?(): void;
  onHide(): void;
  show: boolean;
  title: string;
}

export const NotificationModal: FC<INotificationModalProps> = (props) => {
  const { children, confirmButtonText, cancelButtonText, isDelete, onConfirm, onHide, show, title } = props;
  const [sendDisabled, setSendDisabled] = useState(false);
  useEffect(() => {
    if (show) {
      setSendDisabled(false);
    }
  }, [show]);

  return (
    <Modal
      title={title}
      show={show}
      onHide={() => onHide()}
      buttons={
        onConfirm
          ? [
              <Button type="button" outline key="cancel-modal-btn" onClick={onHide}>
                {cancelButtonText || 'Cancel'}
              </Button>,
              <Button
                type="button"
                primary={!isDelete}
                isDelete={isDelete}
                key="save-modal-btn"
                disabled={sendDisabled}
                onClick={() => {
                  setSendDisabled(true);
                  onConfirm();
                  onHide();
                }}
              >
                {confirmButtonText || 'Confirm'}
              </Button>,
            ]
          : [
              <Button type="button" outline key="ok-modal-btn" onClick={onHide}>
                Close
              </Button>,
            ]
      }
      buttonsJustifyAlign="center"
    >
      <Style.MessageWrapper>{children}</Style.MessageWrapper>
    </Modal>
  );
};
