/* eslint-disable complexity */
import { TransitHouseConsignment } from '@e-origin/shared';
import { FormikProps } from 'formik';
import { useSelector } from 'react-redux';

import { selectTransit } from '../../../../../../../../stores/transitsSlice';
import { retrieveCodes } from '../../../../../../../../stores/settingsSlice';
import { FormContainer, FormRow, FormSection } from '../../../../../../../../styles/common';
import { TransitDuplicateRow } from '../../../../../../components';
import { GoodsItemFormInitializerReturn } from '../goods-item-form-initializer';
import { TransitAutocompleteHeader, TransitInputHeader } from '../../../../../../components';
import { Input } from '../../../../../../../../components';

interface GoodsItemDataProps {
  form: FormikProps<GoodsItemFormInitializerReturn>;
  houseConsignment: TransitHouseConsignment;
  disabled: boolean;
}

export const GoodsItemData: React.FC<GoodsItemDataProps> = (props) => {
  const { form } = props;

  const transit = useSelector(selectTransit);

  return (
    <>
      <FormContainer>
        <FormSection verticalPadding={0}>
          <FormRow>
            <TransitAutocompleteHeader
              form={form}
              parents={[transit.TransitOperation.declarationType]}
              name="declarationType"
              disabled={props.disabled}
              width={25}
              placeholder="Declaration Type"
              code="CL232"
            />
            <TransitAutocompleteHeader
              form={form}
              parents={[transit.Consignment.countryOfDispatch]}
              name="countryOfDispatch"
              disabled={props.disabled}
              width={25}
              placeholder="Country Of Dispatch"
              code="CL008"
            />
            <TransitAutocompleteHeader
              form={form}
              parents={[transit.Consignment.countryOfDestination]}
              name="countryOfDestination"
              disabled={props.disabled}
              width={25}
              placeholder="Country Of Destination"
              code="CL008"
            />
            <TransitInputHeader
              form={form}
              parents={[transit.Consignment.referenceNumberUCR]}
              name="referenceNumberUCR"
              width={25}
              placeholder="Reference Number UCR"
              disabled={props.disabled}
            />
          </FormRow>
          <FormRow>
            <Input
              name="Commodity.descriptionOfGoods"
              placeholder="Description of goods"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.descriptionOfGoods}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="Commodity.CommodityCode.hsCode"
              placeholder="HS code"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.CommodityCode.hsCode}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <TransitDuplicateRow
            addButtonLabel="Add new UNNumber"
            form={form}
            inputs={[
              {
                type: 'Autocomplete',
                label: 'UNNumber',
                name: 'UNNumber',
                width: 25,
                search: (searchText) => retrieveCodes('CL101', searchText),
              },
            ]}
            compact
            arrayPath="Commodity.DangerousGoods"
            disabled={props.disabled}
          />
          <FormRow>
            <Input
              name="GoodsMeasure.grossMass"
              placeholder="Gross mass"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.grossMass}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="GoodsMeasure.netMass"
              placeholder="Net mass"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.netMass}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
            <Input
              name="GoodsMeasure.supplementaryUnits"
              placeholder="Supplementary Units"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              value={form.values.Commodity.GoodsMeasure.supplementaryUnits}
              width={33}
              widthUnit="%"
              compact
              disabled={props.disabled}
            />
          </FormRow>
          <TransitDuplicateRow
            addButtonLabel="Add new package"
            form={form}
            inputs={[
              {
                type: 'Autocomplete',
                label: 'Type of Packages',
                name: 'TypeOfPackages',
                search: (searchText) => retrieveCodes('CL017', searchText),
              },
              {
                type: 'Input',
                label: 'Number of Packages',
                name: 'NumberOfPackages',
              },
              {
                type: 'Input',
                label: 'Marks',
                name: 'ShippingMarks',
              },
            ]}
            arrayPath="Packaging"
            compact
            disabled={props.disabled}
          />
        </FormSection>
      </FormContainer>
    </>
  );
};
