/* eslint-disable complexity */
import { FormikProps } from 'formik';

import { retrieveCodes } from '../../../../../../../../stores/settingsSlice';
import { FormContainer, FormSection, FormSectionTitle } from '../../../../../../../../styles/common';
import { TransitConsignee, TransitConsignor, TransitDuplicateRow } from '../../../../../../components';
import { HouseItemFormInitializerReturn } from '../house-form-initializer';

interface HouseStakeholdersProps {
  form: FormikProps<HouseItemFormInitializerReturn>;
  disabled: boolean;
}

export const HouseStakeholders: React.FC<HouseStakeholdersProps> = (props) => {
  const { form } = props;

  return (
    <>
      <FormContainer>
        <TransitConsignor form={form} editDisabled={props.disabled} />
        <TransitConsignee form={form} editDisabled={props.disabled} />
        <FormSection verticalPadding={0}>
          <FormSectionTitle>Additional supply chain actor</FormSectionTitle>
          <TransitDuplicateRow
            addButtonLabel="Add new additional supply chain actor"
            form={form}
            inputs={[
              {
                type: 'Autocomplete',
                label: 'Role',
                name: 'role',
                search: (searchText) => retrieveCodes('CL704', searchText),
              },
              {
                type: 'Input',
                label: 'Identification number',
                name: 'identificationNumber',
              },
            ]}
            arrayPath="AdditionalSupplyChainActor"
            compact
            disabled={props.disabled}
          />
        </FormSection>
      </FormContainer>
    </>
  );
};
